<section class="current">
  <!-- <p>
      present works!
  </p> -->

  <!-- Current Position Section -->
  <section class="current-work d-md-flex">

    <div class="company d-flex flex-column">
      <!-- add image of company -->
      <p>Currently...</p>
      <h2 class="company-title">Staff Software Engineer</h2>
      <p>for</p>
      <h3 class="company-name">Venmo</h3>
    </div>

    <div class="position d-flex flex-column m-0 m-sm-3">
      <div class="d-flex flex-column">
        <div class="position-description">
          <ul class="bullet-points d-flex flex-column pl-0">
            <li class="point m-2 mb-4">Integrating with cross network systems via RESTful APIs, and asynchronous mechanisms like Webhooks and message queues to ensure synchronisty and data durability.</li>
            <li class="point m-2 mb-4">Expanding foundational microservices to support a wide range of financial products, and regulatory systems in place to identify fraudulent activities.</li>
            <li class="point m-2 mb-4">Improving code quality and infrastructure by modernizing technology to compete with industry leading standards.</li>            
          </ul>
        </div>
        <div class="position-tools">
          <p class="badge badge-dark align-self-center">Python/Django</p>
          <p class="badge badge-light align-self-center">Docker/Kubernetes</p>
          <p class="badge badge-dark align-self-center">MySQL</p>
          <p class="badge badge-light align-self-center">DynamoDB</p>
          <p class="badge badge-dark align-self-center">S3</p>
          <p class="badge badge-light align-self-center">Redis</p>
          <p class="badge badge-dark align-self-center">Kafka</p>
        </div>
      </div>
    </div>
    
  </section>

  <!-- My Reads Section -->
  <section class="current-reads">
    <div style="position:relative">
      <div class="current-reads-wrapper">

        <div class="current-reads-title d-none d-lg-flex align-items-center">
          <h1>I'm Reading...</h1>
        </div>

        <div class="current-reads-img d-flex align-items-center justify-content-center">
          <div class="img-wrapper">
            <img src="{{currentBook.img}}">
          </div>
        </div>


        <div class="current-reads-info">
          <div class="info">
            <div class="book-title">
              <h2>{{currentBook.title}}</h2>
              <h4>{{currentBook.author}}</h4>
            </div>
            
          </div>

          <div class="read-more" 
            [ngClass]="summary ? 'show-summary' : 'hide-summary'">
            {{currentBook.summary}}
            <div class="my-rating">{{currentBook.rating}} out of 5</div>
          </div>

          <div class="expand-summary">
            <button class="expand-button" (click)="toggleBookSummary()">
              <span *ngIf="!summary" class="closed" title="Learn more about this book">
                Learn More
              </span>
              <span *ngIf="summary" class="expanded" title="Minimize book summary">
                Hide
              </span>
            </button>
          </div>
          
        </div>
      
      </div>

      <button class="expand-more-reads" (click)="toggleMoreBooks()">
        <span *ngIf="!bookList">More</span>
        <span *ngIf="bookList">Hide</span>
      </button>
      
    </div>

    

    <div class="current-reads-completed"
      [ngClass]="bookList ? 'show-bookList' : 'hide-bookList'">
      <div class="wrapper">
        <div *ngFor="let book of myReads" class="previous-book">
          <img src="{{book.img}}" title="{{book.title}}">
          <div class="previous-book-info">
            <p>{{book.title}}</p>
            <p>{{book.author}}</p>
          </div>
        </div>
      </div>
      
      <button class="hide-more-reads" (click)="toggleMoreBooks()" title="Collapse book collection"></button>
    </div>

  </section>

  <!-- Current Project / Quote Section -->
  <div class="d-flex flex-md-row flex-column">
      <!-- <section class="current-learnings">
        <h1>Current Passion Project</h1>
        <div class="current-learings-item">
          <h3>Machine Learning</h3>
        </div>
        <div class="book">
          <img src="/assets/Book Covers/HandsOn ML.jpg"
            href="http://a.co/d/9PTHfM3" target="_blank">
        </div>
      </section> -->

      <section class="current-quote">
        <p class="quote">"It is not defeat that destroys you, it is being demoralized by defeat that destroy you."</p>
        <p class="quote-author">Imran Khan</p>
      </section>
  </div>

  <!-- <section class="current-blog">
    blog
  </section> -->
  
</section>