<div class="navigation">

  <nav class="navbar navbar-expand navbar-light">
    <div class="collapse navbar-collapse p-1">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item"
          [ngClass]="past ? 'active' : 'notActive'">
          <a 
            id="past"
            class="nav-link"
            routerLink="/Past"
            (click)="toggleActive($event.target)">Past
          </a>
        </li>
        <li class="nav-item"
          [ngClass]="present ? 'active' : 'notActive'">
          <a
            id="present"
            class="nav-link"
            routerLink="/Present"
            (click)="toggleActive($event.target)">Present
          </a>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link" routerLink="/Future">Future</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/Blog">Blog</a>
        </li> -->
      </ul>
    </div>
  </nav>

</div>