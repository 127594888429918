import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'future',
  templateUrl: './future.component.html',
  styleUrls: ['./future.component.scss']
})
export class FutureComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
