import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  past: boolean = false;
  present: boolean = false;
  constructor(private router: Router, private route: ActivatedRoute) { }

  // method to toggle active class on nav links
  toggleActive(elem) {
    if (elem.id === 'past') {
      this.past = true;
      this.present = false;
    } else if (elem.id === 'present') {
      this.present = true;
      this.past = false;
    }
  }
  
  // TODO: Bug - select active button on init by current path segment
  ngOnInit() {
    this.present = true;
  }

}
