import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainRoutingModule } from './main-routing.module';
import { PastComponent } from './components/past/past.component';
import { PresentComponent } from './components/present/present.component';
import { FutureComponent } from './components/future/future.component';
import { BlogComponent } from './components/blog/blog.component';
import { MainComponent } from './main.component';

@NgModule({
  imports: [
    CommonModule,
    MainRoutingModule
  ],
  declarations: [
    MainComponent,
    PastComponent,
    PresentComponent,
    FutureComponent,
    BlogComponent
  ]
})
export class MainModule { }
