import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PastComponent,
         PresentComponent,
         FutureComponent,
         BlogComponent
       } from './components';
import { MainComponent } from './main.component';

const mainRoutes: Routes = [
  { path: '', redirectTo: 'Present', pathMatch: 'full'
    // children: [
    //   { path: 'Past', component: PastComponent },
    //   { path: 'Present', component: PresentComponent },
    //   { path: 'Future', component: FutureComponent },
    //   { path: 'Blog', component: BlogComponent }
    // ]
  },
  { path: 'Past', component: PastComponent },
  { path: 'Present', component: PresentComponent },
];

@NgModule({
  imports: [
      RouterModule.forChild(mainRoutes)
    ],
  exports: [
      RouterModule
  ],
  declarations: []
})



export class MainRoutingModule { }
