import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'present',
  templateUrl: './present.component.html',
  styleUrls: ['./present.component.scss'],
})
export class PresentComponent implements OnInit {

  constructor() { }

  currentBook: any;
  summary: boolean = false;
  bookList: boolean = false;

  // Array of Book Objects
  // TODO: Move to static JSON file
  myReads = [
    {
      title: 'After the Prophet',
      author: 'Lesley Hazleton',
      summary: "In this gripping narrative history, Leslet Hazleton tells the tragic story at the heart of the ongoing rivalry between the Sunni and Shia branches of Islam, a rift that dominates the news now more than ever. Combining meticulous research and compelling storytelling, After the Prophet explores the volatile intersection of religion and politics, psychology and culture, history and current events. It is an indispensable guide to the depth and power of the Shia-Sunni split",
      rating: 5,
      img: 'assets/Book Covers/After the Prophet.jpeg',
      current: true
    },
    {
      title: 'A Promised Land',
      author: 'Barack Obama',
      summary: "'Obama takes readers on a compelling journey from his earliest political aspirations to the pivotal Iowa caucus victory that demonstrated the power of grassroots activism to the watershed night of November 4, 2008, when he was elected 44th president of the United States, becoming the first African American to hold the nation’s highest office. Reflecting on the presidency, he offers a unique and thoughtful exploration of both the awesome reach and the limits of presidential power, as well as singular insights into the dynamics of U.S. partisan politics and international diplomacy. Obama brings readers inside the Oval Office and the White House Situation Room, and to Moscow, Cairo, Beijing, and points beyond. We are privy to his thoughts as he assembles his cabinet, wrestles with a global financial crisis, takes the measure of Vladimir Putin, overcomes seemingly insurmountable odds to secure passage of the Affordable Care Act, clashes with generals about U.S. strategy in Afghanistan, tackles Wall Street reform, responds to the devastating Deepwater Horizon blowout, and authorizes Operation Neptune’s Spear, which leads to the death of Osama bin Laden.'",
      rating: 5,
      img: 'assets/Book Covers/A Promised Land.jpg',
      current: false
    },
    {
      title: 'Sapiens, A Brief History of Humankind',
      author: 'Yuval Noah Harari',
      summary: "'One Hundred Thousand years ago, at least 6 human species inhabited the earth. Today there is just one. US. Home Sapiens. How did our species succeed in the battle for dominance? Why did our foraging ancestors come together to create cities and kingdoms? How did we come to believe in gods, nations, and human rights; to trust money, books, and laws; and to be enslaved by bureaucracy, timetables, and consumerism? And what will our world be like in the millennia to come?'",
      rating: 5,
      img: 'assets/Book Covers/Sapiens.jpg',
      current: false
    },
    {
      title: 'The First 20 Hours',
      author: 'Josh Kaufman',
      summary: 'This is an instructional read that lays out how to go about learing/acquiring new skills in 20 Hours. The concept is simple, to break down your skill of choice into subskills and practice. He goes into further details into the process with detailed examples of how he obtains new skills via this process. The end goal is to make the process so simple and segmented, that all physical and mental barriers towards skill acquisition can be disolved. ',
      rating: 4,
      img: 'assets/Book Covers/First 20 Hours.jpg',
      current: false
    },
    {
      title: 'Tools of Titans',
      author: 'Tim Ferris',
      summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Dictum fusce ut placerat orci nulla pellentesque dignissim. Turpis massa tincidunt dui ut ornare lectus sit amet est. Integer feugiat scelerisque varius morbi enim nunc. Sit amet luctus venenatis lectus magna fringilla urna. Neque laoreet suspendisse interdum consectetur libero id faucibus nisl. Massa massa ultricies mi quis hendrerit dolor magna. Cursus turpis massa tincidunt dui ut ornare lectus. Erat pellentesque adipiscing commodo elit at imperdiet. Sit amet luctus venenatis lectus magna. Ornare arcu dui vivamus arcu felis bibendum ut tristique. Tincidunt nunc pulvinar sapien et ligula ullamcorper. Eget egestas purus viverra accumsan in nisl nisi scelerisque eu. Malesuada fames ac turpis egestas. Tempor orci dapibus ultrices in iaculis nunc sed. Velit scelerisque in dictum non.',
      rating: 4,
      img: 'assets/Book Covers/Tools of Titans Cover.jpg',
      current: false
    },
    {
      title: 'Tribe of Mentors',
      author: 'Tim Ferris',
      summary: "Tim Ferris has put together a collection well constructed questions to ask the most successful people in their respective fields. Each chapter is a dedicated interview with a 'Mentor' that can shed light on their journey, and what might lie ahead for someone looking to follow their footsteps. This book shares a lot of insight into how a person at that level of success operates, how they got their and what we as mentees should expect.",
      rating: 5,
      img: 'assets/Book Covers/Tribe of Mentors.jpg',
      current: false
    },
    {
      title: 'Astrophysics for People in a Hurry',
      author: 'Neil DeGrasse Tyson',
      summary: 'Exactly what the name says, this book was a quick and interesting read for those who want to feed their curiosity regarding what we know about space.',
      rating: 3,
      img: 'assets/Book Covers/Astrophysics in a hurry.jpg',
      current: false
    },
    {
      title: 'Money, Master the Game',
      author: 'Tony Robbins',
      summary: 'Tony Robbins puts together a collection of insights, strategies and tips from successful investors around the globe. In this book, he digs deep in to the financial market structures in place today, and shares the dos and donts that many average people overlook. Starting with what to do with a 401K and going deeper in to establishing long term financial success, he breaks the process down to digestable segments that are somewhat easy to follow.',
      rating: 4,
      img: 'assets/Book Covers/Master the Game.jpg',
      current: false
    },
    {
      title: 'Be Obsessed or Be Average',
      author: 'Grant Cardone',
      summary: 'Grant Cardone is a self made success story and motivational personality in the real estate space, and in this book lays out his journey and foundational path towards success. He has this cut throat attitude towards achieving your goals, but the read seems more geared towards aspiring salesman much like his former self. All in all it is a good read to boost your motivational drive and to learn further how you can carve out your own space in the profession that you choose.',
      rating: 3,
      img: 'assets/Book Covers/Be Obsessed or Average.jpg',
      current: false
    },
    {
      title: 'The GM',
      author: 'Tom Callahan',
      summary: 'This Biography about Ernie Accorsi, General Manager of the NY Giants, sheds light on a life in the NFL for almost 3 decades. Accorsi was an inspiration to many, but questioned by even more regarding his faith in players that were not considered to be capable of leading the team to the Super Bowl. ',
      rating: 3,
      img: 'assets/Book Covers/The GM.jpg',
      current: false
    },
    {
      title: 'A Game of Thrones',
      author: 'George R.R. Martin',
      summary: "Do I even need to summarize? If you haven't read it, go grab a copy",
      rating: 5,
      img: 'assets/Book Covers/GoT1.jpg',
      current: false
    },
    {
      title: 'A Clash of Kings',
      author: 'George R.R. Martin',
      summary: "What're you doing reading this, seriously go grab a copy!",
      rating: 5,
      img: 'assets/Book Covers/GoT2.jpg',
      current: false
    },
    {
      title: 'A Storm of Swords',
      author: 'George R.R. Martin',
      summary: "Really?",
      rating: 4,
      img: 'assets/Book Covers/GoT3.jpg',
      current: false
    },
    {
      title: 'A Feast for Crows',
      author: 'George R.R. Martin',
      summary: "Why haven't you bought the series yet?",
      rating: 5,
      img: 'assets/Book Covers/GoT4.jpg',
      current: false
    },
  ];

  // Set the current book that I am reading
  private setCurrentRead() {
    for (let i = 0; i < this.myReads.length; i++) {
      if (this.myReads[i].current) {
        this.currentBook = this.myReads[i];
      }
    }
    return this.currentBook;
  }

  toggleBookSummary() {
    this.summary = !this.summary;
  }

  toggleMoreBooks() {
    this.bookList = !this.bookList;
  }

  ngOnInit() {
    this.setCurrentRead();
  }

}
