<div class="banner">

  <div class="background-panel col-12 col-xl-9">
    <h1 class="my-name d-md-none d-sm-block">Mustansir Ali</h1>
  </div>
  
  <div class="banner-content col-12 col-xl-10">
    <div class="d-flex">
      <div class="my-info d-none d-sm-flex justify-content-center align-items-center flex-column">
        <h1 class="my-name">Mustansir Ali</h1>
        <h3 class="my-title">Software Engineer</h3>
        <!-- <p>
          An artist at heart and developer by trade, I am a curious mind limited only by my ever-expanding knowledge. I focus on: <br />
          <span>Developing something worth showcasing</span>,
          <span> providing a service worth utilizing</span>,
          <span> and solving problems that merit our attention</span>.<br />
          Everything else is just details...
        </p> -->
      </div> 
      <div class="pro-pic col-12"><img src="assets/Pro-pic.jpg"></div>
    </div>
  </div>
  

</div>
