import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'past',
  templateUrl: './past.component.html',
  styleUrls: ['./past.component.scss']
})
export class PastComponent {
  jobs: Array<any> = [];
  school: any;

  // TODO: Move all static data to JSON file to read from
  constructor() {      
    this.jobs = [
      {
        title: 'Staff Software Engineer',
        company: 'Venmo',
        duration: 'February 2022 - Current',
        current: true,
        description: [
          'Integrating with cross network systems via RESTful APIs, and asynchronous mechanisms like Webhooks and message queues to ensure synchronisty and data durability.',
          'Expanding foundational microservices to support a wide range of financial products, and regulatory systems in place to identify fraudulent activities.',
          'Improving code quality and infrastructure by modernizing technology to compete with industry leading standards.'
        ],
        tools: [
          'Python',
          'Django',
          'Docker',
          'Kubernetes',
          'EKS',
          'DynamoDB',
          'AuroraDB',
          'Redis',
        ]
      },
      {
        title: 'Software Engineer',
        company: 'Catalyte - 84.51',
        duration: 'July 2019 - January 2022',
        current: false,
        description: [
          'Integrating with Salesforce APIs, aiming for data consolidation and a centralized source of truth, resulting in an improved sales cycle for major business value.',
          'Building middle layer microservices for the client/contact domain, improving downstream business operations.',
          'Creating self management tools to onboard clients and manage user access and permissions.'
        ],
        tools: [
          'Java',
          'Spring',
          'Angular',
          'Typescript',
          'MongoDB',
          'MySQL',
          'RabbitMQ',
          'Docker',
        ]
      },
      {
        title: 'Web Developer',
        company: 'Citi Bank',
        duration: 'August 2017 - July 2019',
        current: false,
        description: [
          'Built a new credit card servicing web app, with components scalable across various client platforms..',
          'Integrated with existing microservices and APIs using Spring Boot.',
          'Architected an omnichannel application design to make account data available, across all Citi retail credit card services, in real-time.',
          'Developed dyanmic application content in CMS databases with regards to multi-language and ADA needs.'
        ],
        tools: [
          'JavaScript',
          'Angular',
          'Typescript',
          'HTML/SCSS',
          'Java',
          'Gulp',
          'CMS',
        ]
      },
      {
        title: 'Tech. Rotational Program',
        company: 'Conversant',
        duration: 'June 2016 - July 2017',
        current: false,
        roles: [
          {
            role: 'QA Engineer',
            tools: [
              'Java',
              'Linux',
              'Gulp',
            ],
            description: [
              'Tested the real-time bidding framework to maximize consumer outreach, meet client goals and find process inefficiencies in a programmatic manner.',
              'Developed new testing methods in parallel with production level products and features, followed by regression testing the outgoing updates with our linux based servers.'
            ]
          },
          {
            role: 'DevOps Engineer',
            tools: [
              'Puppet',
              'RSpec',
              'Jenkins',
              'Bash',
              'Linux',
            ],
            description: [
              'Worked with teams to provide support for post-OS server applications, using Puppet for automation.',
              'Utilized open source technologies such as Jenkins to replicate the holistic impact of applications on production servers throughout the country.',
            ]
          },
          {
            role: 'Network Engineer',
            tools: [
              'OpenDCIM',
              'SolarWinds',
              'Pingdom',
              'Python',
              'Bash',
              'Jenkins',
              'Linux',
            ],
            description: [
              'Configured networking hardware and delivered a production ready setup to monitor network hardware throughout data centers across the country.',
              'Implemented ways to automate tasks with python scripts for device configuration and monitoring.'
            ]
          },

        ],
      },
      {
        title: 'Front-End Developer | Project Lead',
        company: 'iLoveCoding',
        duration: 'November 2015 - May 2016',
        current: false,
        description: [
          'Coordinate with developers and the QA team to relaunch the iLoveCoding website.',
          'Manage website migration from conception to delivery, while creating time constraints for all stages.',
          'Implement JavaScript, HTML and CSS in a single project to fulfill visual and structural requirements.'
        ],
        tools: [
          'JavaScript',
          'AngularJS',
          'Typescript',
          'HTML/SCSS',
          'jQuery',
          'Gulp',
        ]
      },
    ];

    this.school = {
        name: 'Loyola University of Chicago',
        class: '2016',
        major: 'Computer Science'
    };
  }

  public career_state = 'hide_career';
  public school_state = 'hide_school';
  public more_state = 'hide_more';

  toggle(section) {
    switch (section) {
      case 'career':
        if (this.career_state === 'hide_career') {
          this.career_state = 'expand_career';
          this.school_state = 'hide_school';
          this.more_state = 'hide_more';
        } else {
          this.career_state = 'hide_career';
        }
      break;
      case 'school':
        if (this.school_state === 'hide_school') {
          this.school_state = 'expand_school';
          this.career_state = 'hide_career';
          this.more_state = 'hide_more';
        } else {
          this.school_state = 'hide_school';
        }
      break;
      case 'more':
        if (this.more_state === 'hide_more') {
          this.more_state = 'expand_more';
          this.career_state = 'hide_career';
          this.school_state = 'hide_school';
        } else {
          this.more_state = 'hide_more';
        }
      break;
    }
  }

  // ngOnInit() {
  // }

}
