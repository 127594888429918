<section class="past-component min-vh-100 d-flex justify-content-center"> 

  <div class="expandables d-flex flex-column justify-content-center">

    <div class="education mb-5" (click)="toggle('school')">
      <h3 class="expandable-heading">Education</h3>
      <div class="education-expand" [className]="school_state">
        <div class="school-info">
          <div class="img-wrapper">
            <img class="school-img" src="/assets/LUC logo.jpg"/>
          </div>
          <p class="school-name">{{school.name}}</p>
        </div>
        <div class="my-info">
          <p>Bachelor's of Science in {{school.major}}</p>
          <p>Class of {{school.class}}</p>
        </div>
      </div>
    </div>

    <div class="career mb-5" (blur)="toggle('career')">
      <div class="career-heading">
        <h3 class="expandable-heading">Experience</h3>
        <div class="summary">
          <p>Since my first job at the age of 15, I have always found solace in having ownership of my work. I have held positions in an array of industries, 
            and from a student to professional, that mentality has always proved to be true.
            </p>
          <button (click)="toggle('career')">See some of my recent positions</button>
        </div>
      </div>
      
      <div class="career-expand"
        [className]="career_state">
        
        <div *ngFor="let job of jobs" class="job">
          <div class="job-title">
            <p *ngIf="job.current">Currently...</p>
            <p class="title">{{job.title}}</p>
            <p>for</p>
            <p class="company">{{job.company}}</p>
            <p *ngIf="!job.current" class="duration">{{job.duration}}</p>
          </div>
          <div class="job-description">
            <div *ngIf="job.description">
              <ul>
                <li *ngFor="let bullet of job.description" class="description-point">{{bullet}}</li>
              </ul>
              <div class="position-tools">
                <p *ngFor="let tool of job.tools" class="tool">{{tool}}</p>
              </div>
            </div>
            
            <div *ngIf="job.roles">
              <div *ngFor="let role of job.roles" class="role">
                <p>{{role.role}}</p>
                <ul>
                  <li *ngFor="let bullet of role.description" class="description-point">{{bullet}}</li>
                </ul>
                <div class="position-tools">
                  <p *ngFor="let tool of role.tools" class="tool">{{tool}}</p>
                </div>

              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>

    <!-- <h2 class="other-title fancy"><span>Other Projects</span></h2>
    <div class="mobile-only">
      <p>Tap logo to learn more</p>
    </div> -->

    <div class="other">

      <!-- <div class="project">
        <a href="http://bittymd.com" target="_blank">
          <img width="300px" src="assets/logos/Bitty Logo.png">
        </a>
      </div> -->

      <!-- <div class="project">
        <a href="" target="_blank">
          <img width="200px" src="assets/logos/MyView Logo.png">
        </a>
      </div> -->

      <!-- <div class="project">
        <a href="http://falconeyeapp.com/" target="_blank">
          <img width="200px" src="assets/logos/Falcon Logo.png">
        </a>
      </div> -->

    </div>
    <!-- <div class="more">
      <h3 class="expandable-heading" (click)="toggle('more')">More</h3>
      <div class="more-expand" [className]="more_state">
        More here
      </div>
    </div> -->
  </div>
</section>
