<div id="scroll-animate" class="app_main" style="text-align:center">
    
  <div id="scroll-animate-main" class="home-container">
      <banner></banner>
  </div>

  <div class="main-container">
      <navigation></navigation>
      <router-outlet></router-outlet>
  </div>

  <div class="footer-container">
      <div class="footer-content">
          <div class="contact-me">
              <a 
                  href="https://www.linkedin.com/in/mustansir-ali-00023199/"
                  class="linkedIn"
                  title="LinkedIn"
                  target="_blank">
              </a>
              <a 
                  href="https://www.instagram.com/moostaanzer/?hl=en"
                  class="IG"
                  title="Instagram"
                  target="_blank">
              </a>
              <a
                  href="/assets/Mustansir Ali Resume.pdf"
                  class="resume"
                  title="My Resume"
                  target="_blank">
              </a>
          </div>
          
          <h2><span>NOT</span> Powered by Squarespace</h2>
      </div>
  </div>
</div>